import React from "react"
import { css } from "@emotion/core"
import linkResolver from "../utils/linkResolver"
import ContentDecoration from "./UI/ContentDecoration"
import RichText from "./UI/RichText"
import { Link } from "gatsby"
import List from "components/UI/List"
import DateLabel from "components/UI/DateLabel"
import t from "theme"

const QuestionFull = ({ questionData }) => {
    const company = questionData.companies ? questionData.companies[0] : null

    const linkUrl = linkResolver({ slug: questionData.slug, type: "question" })
    const questionBody = questionData.question.json
    const answers = questionData.answer ? questionData.answer : null

    const headline = questionData.headline
    const sortTimestamp = questionData.sortTimestamp

    return (
        <div>
            <div
                css={css`
                    margin-bottom: ${t.space[6]};
                `}>
                <ContentDecoration type="QUESTION" company={company} />

                <h1
                    css={css`
                        padding-top: ${t.space[2]};
                        padding-bottom: ${t.space[2]};
                    `}>
                    <Link to={linkUrl}>{headline}</Link>
                </h1>

                <DateLabel date={sortTimestamp} />
            </div>

            <RichText body={questionBody} linkUrl={linkUrl} />
            {answers && (
                <div>
                    <div
                        css={css`
                            color: ${t.color.primary};
                            font-weight: ${t.font.weight.semibold};
                            padding-top: ${t.space[6]};
                            padding-bottom: ${t.space[3]};
                        `}>
                        {answers.length > 1 ? "ANSWERS" : "ANSWER"}
                    </div>
                    <List
                        items={answers.map((answer, index) => (
                            <>
                                <DateLabel
                                    date={answer.sortTimestamp}
                                    prefix={"Posted on"}
                                    css={css`
                                        margin-bottom: ${t.space[3]};
                                    `}
                                />
                                {answer.interview && (
                                    <Link to={linkResolver({ slug: answer.interview.slug, type: "interviews" })}>
                                        <div
                                            css={css`
                                                font-weight: ${t.font.weight.semibold};
                                                margin-bottom: ${t.space[5]};
                                            `}>
                                            Excerpt from {answer.interview.headline}
                                        </div>
                                    </Link>
                                )}
                                <RichText body={answer.answer.json} linkUrl={linkUrl} />
                            </>
                        ))}
                        space={t.space[5]}
                    />
                </div>
            )}
        </div>
    )
}

export default QuestionFull
