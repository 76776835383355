import React from "react"
import CenteredLayout from "layouts/Centered"
import Header from "components/Common/Header"
import PageMetadata from "../components/Common/PageMetadata"

import QuestionFull from "../components/QuestionFull"
import FeaturedArticlesSidebar from "../components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import { graphql } from "gatsby"
import FloatingMenu from "components/UI/FloatingMenu"
import { useMediaQuery } from "react-responsive"
import t from "theme"

export const query = graphql`
    query($slug: String!) {
        question: contentfulQuestion(slug: { eq: $slug }) {
            ...QuestionContent
        }
    }
`

const QuestionPage = ({ pageContext: { commonData }, data: { question }, location }) => {
    const featuredArticles = question.companies
        ? getFeaturedArticles(commonData, question.companies[0].ticker)
        : getFeaturedArticles(commonData)

    const selectedCompany = question.companies ? question.companies[0] : null

    const isTabletOrPhone = useMediaQuery({
        query: `(max-width: ${t.breakpoint.large})`,
    })

    return (
        <CenteredLayout
            header={
                <Header
                    commonData={commonData}
                    selectedCompany={selectedCompany}
                    selectedFeature={`Q&A`}
                    pathname={location.pathname}
                />
            }
            sidebar={<FeaturedArticlesSidebar title="FEATURED ARTICLES" featureds={featuredArticles} />}>
            <PageMetadata pageContent={question} pagePathname={location.pathname} />

            <QuestionFull questionData={question} />

            {isTabletOrPhone ? <FloatingMenu linkUrl={location.pathname} /> : null}
        </CenteredLayout>
    )
}

export default QuestionPage
